<template>
    <div>
        <section id="virtualPresentaionSec">
            <b-container class="wrap">
                <b-row>
                    <b-col class="title text-left">
                        <h2 class="display-4 freight-big-pro-book">INSPIRED DESIGNS</h2>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="title text-left">
                        <h4 class="display-8 freight-big-pro-book">TOWNHOMES, 40' 43' & 50' SINGLES</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="funcBtns d-flex flex-row acumin-pro-wide-medium">
                        <!--<div class="btnItem">HOME DESIGNS</div>-->
                        <a href="/files/virtual/Zancor_SouthBay_Features_Digital_0623.pdf" target="_blank" class="btnItem">
                            <div>FEATURES</div>
                        </a>
                        <a href="/files/virtual/Zancor_SouthBay_SPHandout_Digital_1stRel_0623.pdf" target="_blank" class="btnItem">
                            <div>SITEPLAN</div>
                        </a>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center">
                        <div class="tabToggleWrap">
                            <div v-for="(tab, name, index) in tabs" :key="index" class="oneBtn">
                                <div @click="changeData(name)" :class="[{active:name == currentTab}, '', 'freight-big-pro-book', 'btnItem']" :style="[name == currentTab ? {borderColor: tab.bgcolor, backgroundColor:tab.bgcolor, color:tab.color} : {borderColor: tab.bgcolor, backgroundColor:'transparent', color:'#333'}]">
                                    {{tab.name}}
                                </div>
                            </div>
                        </div>
                        <!-- elevation bar for detached homes -->
                        <div class="elevToggleWrap detached" v-if="currentData.type=='detached'">
                            <div v-for="(elev, name, index) in currentElevs" :key="index">
                                <div @click="changeElev(name)" :class="[{active:name == currentElev}, '', 'elevBtn']">
                                    <span class="textDecor">{{elev}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- elevation bar for town homes -->
                        <div class="elevToggleWrap" v-if="currentData.type=='town'">
                            <div v-for="(elev, name, index) in currentElevs" :key="index">
                                <div @click="changeElev(name)" :class="[{active:name == currentElev}, '', 'elevBtn']">
                                    <span class="textDecor">{{elev}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- wrap for detached homes -->
                        <div class="modelsOutWrapDetached" v-if="currentData.type=='detached'">
                            <div v-for="(data, i) in currentData.models" :key="i" :class="[{defaultStyle:currentSliceIndex == i}, 'detachedItems']" @mouseover="changeCurrentSliceInx(i)" @mouseleave="initialSliceIndex_D()">
                                <div class="imgWrap">
                                    <img :src="data.modelImg" class="img-fluid"/>
                                </div>
                                <!-- <div class="details" :style="{backgroundColor:currentData.bgcolor}"> -->
                                <div class="bgColorLayerDetach" :style="{backgroundColor:currentData.bgcolor}"></div>
                                <div class="details">
                                    <div class="sBayLogo"><img src="/img/virtual/sbay_logo.svg" class="img-fluid"/></div>
                                    <div class="title1 r-bold  freight-big-pro-book">{{data.name}}</div>
                                    <div class="extraInfo r-bold ">{{data.extra}}</div>
                                    <div class="midWrap">
                                        <!-- <div class="sqft r-regular">{{data.elev}}</div>
                                        <div class="sqft r-regular">{{data.sqft}} sq.ft</div> -->
                                        <div class="sqft r-regular" v-html="data.elev"></div>
                                        <div class="sqft r-regular" v-html="data.sqft"></div>
                                    </div>
                                    <a :href="data.btnLink" target="_blank" class="aLink">
                                        <div class="linkBtn r-regular">{{data.btn}}</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- wrap for town homes -->
                        <div class="modelsOutWrap" v-if="currentData.type=='town'">
                            <div class="modelsWrap">
                                <div class="imgContainner" v-if="currentData.multiElev == true">
                                    <b-img :src="currentData.bgImg[currentElev]" class="img-fluid"/>
                                </div>
                                <div class="imgContainner" v-else>
                                    <b-img :src="currentData.bgImg" class="img-fluid"/>
                                </div>
                                <div class="modelSlice" v-if="currentData.multiElev == true">
                                    <!-- <div :class="[{defaultStyle:currentSliceIndex == i}, 'oneSlice']" v-for="(data, i) in currentData.models[currentElev]" :key="i" :style="{width:data.width}" @mouseover="changeCurrentSliceInx(i)" @mouseleave="currentSliceIndex = 0"> -->
                                    <div :class="[{defaultStyle:currentSliceIndex == i}, 'oneSlice']" v-for="(data, i) in currentData.models[currentElev]" :key="i" :style="{width:data.width}" @mouseover="changeCurrentSliceInx(i)" @mouseleave="initialSliceIndex_D()">
                                        <div class="sliceContent">
                                            <!-- <div class="title1 r-bold ">{{data.name}}</div> -->
                                            <div class="topPart" :style="[currentSliceIndex == i?{backgroundColor:currentData.bgcolor}:{}]">
                                                <div class="title1 r-bold  freight-big-pro-book" v-html="data.name"></div>
                                                <div class="topPartDetail">
                                                    <div class="extraInfo r-bold " v-if="data.extra">{{data.extra}}</div>
                                                    <div class="sqft r-regular">{{data.sqft}} sq.ft</div>
                                                </div>
                                            </div>
                                            <div class="townLinkWrap" :style="[currentSliceIndex == i?{backgroundColor:currentData.bgcolor}:{}]">
                                                <a :href="data.btnLink" target="_blank" class="aLink">
                                                    <div class="linkBtn r-regular ">{{data.btn}}</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modelSlice" v-else>
                                    <!-- <div :class="[{defaultStyle:currentSliceIndex == i}, 'oneSlice']" v-for="(data, i) in currentData.models" :key="i" :style="{width:data.width}" @mouseover="changeCurrentSliceInx(i)" @mouseleave="currentSliceIndex = 0"> -->
                                    <div :class="[{defaultStyle:currentSliceIndex == i}, 'oneSlice']" v-for="(data, i) in currentData.models" :key="i" :style="{width:data.width}" @mouseover="changeCurrentSliceInx(i)" @mouseleave="initialSliceIndex_D()">
                                        <div class="sliceContent">
                                            <!-- <div class="title1 r-bold ">{{data.name}}</div> -->
                                            <div class="title1 r-bold  freight-big-pro-book" v-html="data.name"></div>
                                            <div class="extraInfo r-bold">{{data.extra}}</div>
                                            <div class="sqft r-regular  ">{{data.sqft}} sq.ft</div>
                                            <a :href="data.btnLink" target="_blank" class="aLink">
                                                <div class="linkBtn r-regular ">{{data.btn}}</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- wrap for detached homes -->
                        <div class="bigBgWrapDetached_M" v-if="currentData.type=='detached'">
                            <!-- detached -->
                        </div>
                        <!-- wrap for town homes mobile -->
                        <div class="bigBgWrap_M" v-if="currentData.type=='town'">
                            <div class="left">
                                <div class="arrowL" @click="changeCurrentSliceInxByArrow(-1)">
                                    <img src="/img/arrow-left-solid.svg" class="img-fluid"/>
                                </div>
                            </div>
                            <div class="middle">
                                <div class="bgWrap" >
                                    <div class="rescaleImg">
                                        <img class="imgMover" :src="bgMoveMatch.src" :style="{transform: `translateX(${bgMoveMatch.pos==0 ? 0:-(bgMoveMatch.pos - moveDistanceAdjustment_M) +'%'})`}"/>
                                    </div>
                                </div>
                                <div class="textWrap" :style="{backgroundColor:currentData.bgcolor, color:currentData.color}">
                                    <!-- <div class="r-bold title">{{bgMoveMatch.model["name"]}}</div> -->
                                    <div class="r-bold title freight-big-pro-book" v-html="bgMoveMatch.model['name']"></div>
                                    <div class="topPartDetailMobile d-flex flex-row justify-content-center">
                                        <div class="r-bold extra">{{bgMoveMatch.model["extra"]}}</div>
                                        <div class="r-regular sqft">{{bgMoveMatch.model["sqft"]}}  sq.ft</div>
                                    </div>
                                    <a :href="bgMoveMatch.model.btnLink" target="_blank" class="aLink">
                                        <div class="linkBtn r-regular " :style="{color:currentData.color}">{{bgMoveMatch.model.btn}}</div>
                                    </a>
                                </div>
                            </div>
                            <div class="right">
                                <div class="arrowR" @click="changeCurrentSliceInxByArrow(1)">
                                    <img src="/img/arrow-left-solid.svg" class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="relatedLink">
                            <div class="oneBtn" v-for="(btn, index) in modelGneralInfo.relatedlinks" :key="index">
                                <a :href="btn.link" target="_blank" class="oneBtnLink">
                                    <div>{{btn.name}}</div>
                                </a>
                            </div>
                        </div> -->
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import jq from 'jquery';
import _ from 'lodash';
    export default {
        data() {
            return {
                currentTab:"",
                tabs:{},
                // set up multi elevation tabs
                currentElevs:{},
                // set up multi elevation tabs
                currentElev:"",
                currentData:{
                    
                },
                currentSlice:null,
                currentSliceIndex:0,
                curntBgImgInfo:{
                    imgSrc:"",

                },
                formerElev:"",
                moveDistanceAdjustment_M:6,
                modelInfo:{
                    "towns":{
                        "diplayName":"TOWNS",
                        "color":"#333",
                        "bgcolor":"#e0c500",
                        "bgImg":{
                            "elevA":"/img/virtual/towns/th-blk-a.jpg",
                            "elevB":"/img/virtual/towns/th-blk-b.jpg",
                        },
                        "type":"town",
                        // "relatedlinks":[
                        //     {"name":"VIEW SITE PLAN", "link":""},
                        //     // {"name":"VIEW AGREEMENT OF PURCHASE & SALE", "link":"/files/"},
                        //     // {"name":"VIEW DEPOSIT STRUCTURE", "link":"/files/"},
                        //     {"name":"DOWNLOAD ALL FLOOR PLANS", "link":"/files/virtual/virtualPresentation/Arista_NewKlein_20TownFPBook_030123.pdf"},
                        // ],
                        "multiElev":true,
                        "elevBtn":{
                            "elevA":{"diplayName":"Elevation A"},
                            "elevB":{"diplayName":"Elevation B"},
                        },
                        "models":{
                            "elevA":[
                                    {
                                        "name":"THE SPRING",
                                        "extra":"Elev. A",
                                        "sqft":"2,001",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Spring.pdf",
                                        "width":"22.5%"
                                    },
                                    {
                                        "name":"THE GULF",
                                        "extra":"Elev. A",
                                        "sqft":"1,655",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Gulf.pdf",
                                        "width":"18.5%"
                                    },
                                    {
                                        "name":"THE CREEK",
                                        "extra":"Elev. A",
                                        "sqft":"1,780",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Creek.pdf",
                                        "width":"18.5%"
                                    },
                                    {
                                        "name":"THE CANAL",
                                        "extra":"Elev. A",
                                        "sqft":"1,877",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Canal.pdf",
                                        "width":"18.5%"
                                    },
                                    {
                                        "name":"THE BROOK",
                                        "extra":"Elev. A",
                                        "sqft":"2,056",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Brook.pdf",
                                        "width":"22%"
                                    }
                            ],
                            "elevB":[
                                    {
                                        "name":"THE BROOK",
                                        "extra":"Elev. B",
                                        "sqft":"2,046",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Brook.pdf",
                                        "width":"26.5%"
                                    },
                                    {
                                        "name":"THE CANAL",
                                        "extra":"Elev. B",
                                        "sqft":"1,876",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Canal.pdf",
                                        "width":"16%"
                                    },
                                    {
                                        "name":"THE CREEK",
                                        "extra":"Elev. B",
                                        "sqft":"1,784",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Creek.pdf",
                                        "width":"16.5%"
                                    },
                                    {
                                        "name":"THE GULF",
                                        "extra":"Elev. B",
                                        "sqft":"1,655",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Gulf.pdf",
                                        "width":"16.5%"
                                    },
                                    {
                                        "name":"THE SPRING",
                                        "extra":"Elev. B",
                                        "sqft":"2,002",
                                        "btn":"VIEW FLOOR PLAN",
                                        "btnLink":"/files/virtual/town/The-Spring.pdf",
                                        "width":"24.5%"
                                    }
                            ],
                         },
                    },
                    "40singles":{
                        "diplayName":"40’ SINGLES",
                        "color":"#333",
                        "bgcolor":"#5ab2c0",
                        "type":"detached",
                        "multiElev":false,
                        "models":[
                            {
                                "name":"BEAVER",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/40/beaver.jpg",
                                "sqftMin":"1780",
                                "sqft":"1,780 sq.ft</br>1,780 sq.ft</br>1,780 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/40/Beaver.pdf",
                            },
                            {
                                "name":"COLDWATER",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/40/coldwater.jpg",
                                "sqftMin":"2270",
                                "sqft":"2,277 sq.ft</br>2,276 sq.ft</br>2,270 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/40/Coldwater.pdf",
                            },
                            {
                                "name":"MARINE",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C</br>Elev. D",
                                "modelImg":"/img/virtual/40/marine.jpg",
                                "sqftMin":"2805",
                                "sqft":"2,812 sq.ft</br>2,811 sq.ft</br>2,809 sq.ft</br>2,805 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/40/Marine.pdf",
                            },
                            {
                                "name":"NOTTAWASAGA",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/40/nottawasaga.jpg",
                                "sqftMin":"1387",
                                "sqft":"1,387 sq.ft</br>1,387 sq.ft</br>1,387 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/40/Nottawasaga.pdf",
                            },
                            {
                                "name":"SEVERN",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C</br>Elev. D",
                                "modelImg":"/img/virtual/40/severn.jpg",
                                "sqftMin":"2065",
                                "sqft":"2,051 sq.ft</br>2,065 sq.ft</br>2,105 sq.ft</br>2,065 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/40/Severn.pdf",
                            }
                        ]
                    },
                    "43singles":{
                        "diplayName":"43’ SINGLES",
                        "color":"#333",
                        "bgcolor":"#628b99",
                        "type":"detached",
                        "multiElev":false,
                        "models":[
                            {
                                "name":"BOWERS",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/43/bowers.jpg",
                                "sqftMin":"2217",
                                "sqft":"2,222 sq.ft</br>2,218 sq.ft</br>2,217 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/43/Bowers.pdf",
                            },
                            {
                                "name":"COBOURG",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C</br>Elev. D",
                                "modelImg":"/img/virtual/43/coburg.jpg",
                                "sqftMin":"3110",
                                "sqft":"3,117 sq.ft</br>3,110 sq.ft</br>3,125 sq.ft</br>3,127 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/43/Coburg.pdf",
                            },
                            {
                                "name":"HOLLAND",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/43/holand.jpg",
                                "sqftMin":"1649",
                                "sqft":"1,649 sq.ft</br>1,649 sq.ft</br>1,649 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/43/Holand.pdf",
                            },
                            {
                                "name":"HURON",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C</br>Elev. D",
                                "modelImg":"/img/virtual/43/huron.jpg",
                                "sqftMin":"2651",
                                "sqft":"2,654 sq.ft</br>2,651 sq.ft</br>2,654 sq.ft</br>2,668 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/43/Huron.pdf",
                            },
                            {
                                "name":"MARL",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/43/marl.jpg",
                                "sqftMin":"2425",
                                "sqft":"2,425 sq.ft</br>2,441 sq.ft</br>2,429 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/43/Marl.pdf",
                            },
                            {
                                "name":"SAUBLE",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/43/sauble.jpg",
                                "sqftMin":"2164",
                                "sqft":"2,164 sq.ft</br>2,164 sq.ft</br>2,164 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/43/Sauble.pdf",
                            },
                            {
                                "name":"TALBOT",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/43/talbot.jpg",
                                "sqftMin":"1564",
                                "sqft":"1,564 sq.ft</br>1,568 sq.ft</br>1,568 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/43/Talbot.pdf",
                            }
                        ]
                    },
                    "50singles":{
                        "diplayName":"50’ SINGLES",
                        "color":"#fff",
                        "bgcolor":"#263a53",
                        "type":"detached",
                        "multiElev":false,
                        "models":[
                            {
                                "name":"BONDI",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C</br>Elev. D",
                                "modelImg":"/img/virtual/50/bondi.jpg",
                                "sqftMin":"3638",
                                "sqft":"3,638 sq.ft</br>3,638 sq.ft</br>3,670 sq.ft</br>3,699 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/50/Bondi.pdf",
                            },
                            {
                                "name":"DORAN",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/50/doran.jpg",
                                "sqftMin":"1652",
                                "sqft":"1,652 sq.ft</br>1,652 sq.ft</br>1,656 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/50/Doran.pdf",
                            },
                            {
                                "name":"HUMBER",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C</br>Elev. D",
                                "modelImg":"/img/virtual/50/humber.jpg",
                                "sqftMin":"2701",
                                "sqft":"2,701 sq.ft</br>2,722 sq.ft</br>2,748 sq.ft</br>2,756 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/50/Humber.pdf",
                            },
                            {
                                "name":"NIPISSING",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C</br>Elev. D",
                                "modelImg":"/img/virtual/50/nipissing.jpg",
                                "sqftMin":"3098",
                                "sqft":"3,098 sq.ft</br>3,157 sq.ft</br>3,139 sq.ft</br>3,154 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/50/Nipissing.pdf",
                            },
                            {
                                "name":"PINE",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/50/pine.jpg",
                                "sqftMin":"2500",
                                "sqft":"2,500 sq.ft</br>2,560 sq.ft</br>2,500 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/50/Pine.pdf",
                            },
                            {
                                "name":"SEPODY",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/50/sepody.jpg",
                                "sqftMin":"1763",
                                "sqft":"1,763 sq.ft</br>1,778 sq.ft</br>1,778 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/50/Sepody.pdf",
                            },
                            {
                                "name":"SPRINGWATER",
                                "extra":"",
                                "elev":"Elev. A</br>Elev. B</br>Elev. C",
                                "modelImg":"/img/virtual/50/springwater.jpg",
                                "sqftMin":"2260",
                                "sqft":"2,267 sq.ft</br>2,260 sq.ft</br>2,260 sq.ft",
                                "btn":"VIEW FLOOR PLAN",
                                "btnLink":"/files/virtual/50/Springwater.pdf",
                            }
                        ]
                    }
                },
                modelGneralInfo:{
                    "relatedlinks":[
                        {"name":"VIEW SITE PLAN", "link":"/files/seatonville/virtualPresentation/Seatonville-Site-Plan.pdf"},
                        // {"name":"VIEW AGREEMENT OF PURCHASE & SALE", "link":"/files/seatonville/virtualPresentation/APSDRAFT.pdf"},
                        // {"name":"VIEW DEPOSIT STRUCTURE", "link":"/files/"},
                        // {"name":"DOWNLOAD ALL FLOOR PLANS", "link":"/files/seatonville/virtualPresentation/Arista_NewKlein_20'TownFPBook_030123_3_WEB.pdf"},
                        // {"name":"VIEW FEATURES AND FINISHES", "link":"/files/seatonville/virtualPresentation/Seatonville-Features-&-Finishes.pdf"},
                        {"name":"VIEW TOWNHOME APS", "link":"/files/seatonville/virtualPresentation/Seatonville_FullAPS_TOWNS.pdf"},
                        {"name":"VIEW DETACHED APS", "link":"/files/seatonville/virtualPresentation/Seatonville_FullAPS_SINGLES.pdf"},
                        {"name":"VIEW TOWNHOME BROCHURE", "link":"/files/seatonville/virtualPresentation/Seatonville-Townhome-Brochure.pdf"},
                        {"name":"VIEW DETACHED BROCHURE", "link":"/files/seatonville/virtualPresentation/Seatonville-Detached-Brochure.pdf"},
                        {"name":"VIEW DEPOSIT STRUCTURE AND BONUS", "link":"/files/seatonville/virtualPresentation/Deposit-Structure-&-Bonus-Sheet.pdf"},
                        {"name":"VIEW DARE TO COMPARE HANDOUT", "link":"/files/seatonville/virtualPresentation/Dare-To-Compare-Sheet.pdf"},
                        // {"name":"VIEW ARISTA VALUE-ADDED FEATURES", "link":"/files/seatonville/virtualPresentation/Arista_NewKlein_DepositStructure_Handout_033023_6_UPGRADES_HR.pdf"},
                    ],
                },
            }
        },
        created(){
            this.currentTab = Object.keys(this.modelInfo)[0];
            this.currentData = this.modelInfo[this.currentTab];

            if(this.currentData["type"] == "town"){
            //if its "multiElev":true, then get initial elevation info.
                if(this.currentData["multiElev"]){
                    // if its "multiElev":true, to find the first elevation's value
                    this.currentElev = Object.keys(this.currentData.models)[0];
                    // if its "multiElev":true, to genrate Elevation buttons' data
                    for(const prop in this.currentData["elevBtn"]){
                        this.currentElevs[prop] = this.currentData["elevBtn"][prop]["diplayName"];
                    }
                    // console.log(this.currentElevs);
                }else{
                    // set up multi elevation tabs
                    this.currentElev = "";
                    this.currentElevs = {};
                }
            }else if(this.currentData["type"] == "detached"){
                // set up multi elevation tabs
                this.currentElev = "";
                this.currentElevs = {};
                //sort model for detached here
                // this.currentData.models = _.orderBy(this.currentData.models, ['sqftMin'], ['asc']);
                console.log("detach all - created");
            }

            for(const prop in this.modelInfo){
                // this.tabs[prop] = this.modelInfo[prop]["diplayName"];
                this.tabs[prop] = {};
                this.tabs[prop]["name"] = this.modelInfo[prop]["diplayName"];
                this.tabs[prop]["color"] = this.modelInfo[prop]["color"];
                this.tabs[prop]["bgcolor"] = this.modelInfo[prop]["bgcolor"];
                // console.log(this.tabs[prop]);
                // console.log(this.tabs[prop]["name"]);
                // this.tabs[prop] = this.modelInfo[prop]["diplayName"];
                // this.tabs[prop+"Color"] = this.modelInfo[prop]["color"];
                // assign button's key and value (display name)
            }
            // console.log(this.currentData);
            // console.log(this.tabs);
            // console.log(this.currentElev);
        },
        updated(){
            // console.log("updated");
            // console.log(gsap);
            // console.log(this.currentTab);
            // this.formerElev = this.currentElev;
            // console.log(this.formerElev, this.currentElev);
            if(this.currentData["type"] == "detached"){
                jq(".modelsOutWrapDetached .detachedItems").addClass("fade-in");
                // setTimeout(() => {
                //     jq(".modelsOutWrapDetached .detachedItems").removeClass("fade-in");
                // }, 1500);
            // }else if(this.currentData["type"] == "town" && this.formerElev != this.currentElev){
            //     console.log("toggle elev");
            //     jq(".modelsOutWrap .imgContainner").addClass("fade-in");
            //     this.formerElev = this.currentElev;
            //     setTimeout(() => {
            //        jq(".modelsOutWrap .imgContainner").removeClass("fade-in"); 
            //     }, 1500);
            }else if(this.currentData["type"] == "town"){
                jq(".modelsOutWrap .imgContainner").addClass("fade-in");
                this.formerElev = this.currentElev;
            }
            
            
        },
        mounted(){
            // console.log(this.currentTab);
            this.currentData = this.modelInfo[this.currentTab];
            this.sortDetachModel(this.currentData);
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.checkUrlAction()
                }, 1000)
            })
        },
        methods: {
            checkUrlAction(){
                /**check URL fp link via eblast, toggle to the proper tab at F.P section 
                 * e.g. https://riversedgewasaga.ca/?goto=virtual-presentation&fpOpen=40singles&modelOpen=2
                 * */
                this.query = this.$route.query;
                let fpOpen = this.$route.query["fpOpen"];
                let modelOpen = this.$route.query["modelOpen"];
                console.log(fpOpen);
                if(fpOpen){
                    this.changeData(fpOpen);
                };
                if(modelOpen){
                    this.changeCurrentSliceInx(modelOpen);
                }
            },
            changeData(currentTab) {
                this.currentTab = currentTab;
                this.currentData = this.modelInfo[currentTab];
                console.log("######");
                // console.log(this.currentData);
                if(this.currentData["type"] == "town"){
                    if(this.modelInfo[currentTab]["multiElev"]){
                        // if its "multiElev":true, to find the first elevation's value
                        this.currentElev = Object.keys(this.currentData.models)[0];
                        // if its "multiElev":true, to genrate Elevation buttons' data
                        for(const prop in this.currentData["elevBtn"]){
                            this.currentElevs[prop] = this.currentData["elevBtn"][prop]["diplayName"];
                        }
                        // console.log(this.currentElevs);
                        console.log("change data");
                    }else{
                        // set up multi elevation tabs
                        this.currentElev = "";
                        this.currentElevs = {};
                    }
                    // re-initial black slice pos
                    this.initialSliceIndex_D();
                    this.initialSliceIndex_M();
                }else if(this.currentData["type"] == "detached"){
                    // set up multi elevation tabs
                    this.currentElev = "";
                    this.currentElevs = {};
                    console.log("detach all");
                    //sort sqftMin for detached models
                    this.sortDetachModel(this.currentData);
                    console.log(this.currentData);
                }
            },
            changeElev(currentElev){
                this.currentElev = currentElev;
                this.initialSliceIndex_D();
                this.initialSliceIndex_M();
                // console.log(this.currentElev);
            },
            changeCurrentSliceInx(ind){
                this.currentSliceIndex = ind;
                // console.log("tab",this.currentSliceIndex);
            },
            changeCurrentSliceInxByArrow(dir){
                if(Number(this.currentSliceIndex) + Number(dir) >= 0){
                    if(this.modelInfo[this.currentTab]["multiElev"]){
                        if (Number(this.currentSliceIndex) + Number(dir) < Number(this.currentData.models[this.currentElev].length)){
                           this.currentSliceIndex = Number(this.currentSliceIndex) + Number(dir);
                        }
                    }else{
                        if (Number(this.currentSliceIndex) + Number(dir) < Number(this.currentData.models.length)){
                           this.currentSliceIndex = Number(this.currentSliceIndex) + Number(dir);
                        }
                    }
                }
                // console.log("Arrow", this.currentSliceIndex);
            },
            initialSliceIndex_D(){
                if(jq(window).width() >= 450){
                    this.currentSliceIndex = 0;
                    // console.log("re-init=============")
                }
            },
            initialSliceIndex_M(){
                if(jq(window).width() < 450){
                    this.currentSliceIndex = 0;
                }
            },
            sortDetachModel(curData){
                if(curData.models && curData.type == "detached"){
                    curData.models = _.orderBy(curData.models, ['sqftMin'], ['asc']);
                    // console.log("sorted");
                }
            }
        },
        computed: {
            bgMoveMatch() {
                let currentModelSet, tempBgPos = {}, tempPosNumber = 0;
                //if there is multi-elevation
                if(this.currentData["multiElev"]){
                    currentModelSet = this.currentData.models[this.currentElev];
                    currentModelSet.forEach(function (item, index) {
                        if(index == 0){
                            tempBgPos[index] = tempPosNumber;
                        }else{
                            tempPosNumber = tempPosNumber + Number(currentModelSet[index-1].width.replace("%", ""));
                            tempBgPos[index] = tempPosNumber;
                        }
                    });
                    // console.log(tempBgPos);
                    return {
                        "pos":tempBgPos[this.currentSliceIndex], 
                        "src":this.currentData.bgImg[this.currentElev], 
                        "model": this.currentData.models[this.currentElev][this.currentSliceIndex]
                    };
                }else{
                    currentModelSet = this.currentData.models;
                    currentModelSet.forEach(function (item, index) {
                        if(index == 0){
                            tempBgPos[index] = tempPosNumber;
                        }else{
                            tempPosNumber = tempPosNumber + Number(currentModelSet[index-1].width.replace("%", ""));
                            tempBgPos[index] = tempPosNumber;
                        }
                    });
                    // console.log(tempBgPos);
                    return {
                        "pos":tempBgPos[this.currentSliceIndex], 
                        "src":this.currentData.bgImg, 
                        "model": this.currentData.models[this.currentSliceIndex]
                    };
                }
                
            }
        },
    }
</script>

<style lang="scss" scoped>
@import "./assets/styles/variables";
#virtualPresentaionSec{
    .wrap{
        padding-top:3rem;
        padding-bottom:3rem;
        .title{}
        .funcBtns{
            .btnItem{
                padding-left:2rem;
                padding-right:2rem;
                border-right:solid 2px #333;
                color: $m-blue;
                &:first-of-type{
                    padding-left:0rem;
                }
                &:last-of-type{
                    border-right:none;
                }
            }
        }
        .tabToggleWrap{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin:1rem auto 2rem auto;
            @media screen and (max-width:450px){
                justify-content: flex-start;
                margin:1rem auto;
            }
            .active{
                // font-weight:bold;
            }
            .oneBtn{
                padding:0px 10px;
                margin:10px 0px;
                font-size:1.35rem;
                min-width:240px;
                cursor: pointer;
                // border-right: 2px solid #008C99;
                // &:last-of-type{
                //     border-right:none;
                // }
                @media screen and (max-width:450px){
                    font-size:0.85rem;
                    min-width:100px;
                }
                .btnItem{
                    border-bottom: solid 2px;
                }
            }
        }
        .elevToggleWrap{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            height: calc(2rem + 5px);
            cursor: pointer;
            border-bottom: 5px solid rgba(39,87,107,0.8);
            &.detached{
                border-bottom: 5px solid transparent;
            }
            .elevBtn{
                width:10rem;
                height: 2rem;
                background-color: rgba(39,87,107,0.25);
                transition: all 0.25s ease;
                padding-top:0.25rem;
                .textDecor{
                    height: 1.75rem;
                    display: inline-block;
                    -webkit-box-shadow: inset 0px 0px 0px 0px #fff; 
                    box-shadow: inset 0px 0px 0px 0px #fff;
                }
                &.active{
                    // font-weight:bold;
                    color: #fff;
                    background-color: rgba(39,87,107,0.8);
                    .textDecor{
                        height: 1.75rem;
                        display: inline-block;
                        -webkit-box-shadow: inset 0px -2px 0px 0px #fff; 
                        box-shadow: inset 0px -2px 0px 0px #fff;
                    }
                }
            }
            
        }
        .modelsOutWrap{
            height:auto;
            @media screen and (max-width:450px){
                // height:110vh;
            }
            .modelsWrap{
                position: relative;
                @media screen and (max-width:450px){
                    // transform:rotate(90deg) scale(2) translateX(150px);

                }
                .imgContainner{
                    width:100%;
                }
                .modelSlice{
                    display:flex;
                    flex-direction:row;
                    justify-content:flex-start;
                    width:100%;
                    height: 100%;
                    position: absolute;
                    top:0px;
                    left:0px;
                    @media screen and (max-width:450px){
                        // transform:rotate(90deg);
                    }
                    .oneSlice{
                        height: 100%;
                        // &:hover, &.defaultStyle:first-of-type{
                        &:hover, &.defaultStyle{
                            .sliceContent{
                                -webkit-animation: fade-in 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                animation: fade-in 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                background-color: rgba(0,0,0,0);
                                -webkit-box-shadow: inset 0px 0px 0px 1px rgba(255,255,255,1);
                                -moz-box-shadow: inset 0px 0px 0px 1px rgba(255,255,255,1);
                                box-shadow: inset 0px 0px 0px 1px rgba(255,255,255,1);
                                .title1{
                                    // opacity:1;
                                    -webkit-animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                    animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                    animation-name: fade-in, slide-in-bottom;
                                }
                                .extraInfo{
                                    -webkit-animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                    animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                    animation-name: fade-in, slide-in-bottom;
                                }
                                .sqft{
                                    -webkit-animation: fade-in 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                    animation: fade-in 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                    animation-name: fade-in, slide-in-bottom;
                                }
                                .aLink{
                                    -webkit-animation: fade-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                    animation: fade-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                    animation-name: fade-in, slide-in-bottom;
                                }
                            }
                        }
                        .sliceContent{
                            background-color: rgba(0,0,0,0);
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            .topPart{
                                width:100%;
                                padding:0.5rem 0.3rem;
                                .title1{
                                    // font-size: 1.5rem;
                                    font-size: 1rem;
                                    line-height: 120%;
                                    opacity:0;
                                    // transition:opacity .3s ease-in-out 0s;
                                    @media screen and (max-width:1300px){
                                        font-size: 1.25rem;
                                    }
                                    @media screen and (max-width:992px){
                                        font-size: 1.1rem;
                                    }
                                    @media screen and (max-width:450px){
                                        font-size: 0.5rem;
                                    }
                                }
                                .topPartDetail{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    .extraInfo{
                                        // font-size: 1.5rem;
                                        font-size: 1rem;
                                        opacity:0;
                                        line-height: 100%;
                                        // transition:opacity .3s ease-in-out 0s;
                                        border-right:#333 1px solid;
                                        padding-right:0.5rem;
                                        @media screen and (max-width:1300px){
                                            font-size: 0.75rem;
                                        }
                                        @media screen and (max-width:992px){
                                            font-size: 0.75rem;
                                        }
                                        @media screen and (max-width:450px){
                                            font-size: 0.5rem;
                                            padding-right:0.25rem;
                                        }
                                    }
                                    .sqft{
                                        opacity:0;
                                        // transition:opacity .3s ease-in-out 0s;
                                        font-size: 1rem;
                                        line-height: 100%;
                                        padding-left:0.5rem;
                                        @media screen and (max-width:1300px){
                                            font-size: 0.75rem;
                                        }
                                        @media screen and (max-width:992px){
                                            font-size: 0.75rem;
                                        }
                                        @media screen and (max-width:450px){
                                            font-size: 0.5rem;
                                            padding-left:0.25rem;
                                        }
                                    }
                                }
                            }
                            .townLinkWrap{
                                width:100%;
                                padding:0.5rem;
                                .aLink{
                                    // width:80%;
                                    margin-top:2rem;
                                    opacity:0;
                                    // transition:opacity .3s ease-in-out 0s;
                                    color: #333;
                                    @media screen and (max-width:450px){
                                        margin-top:2rem;
                                    }
                                    &:hover{
                                        text-decoration: none;
                                        color: #333;
                                    }
                                    .linkBtn{
                                        text-align: center;
                                        padding:5px 0px;
                                        max-width:100%;
                                        border: 1px  solid;
                                        font-size:12px;
                                        color: #333;
                                        @media screen and (max-width:1300px){
                                            font-size:10px;
                                        }
                                        @media screen and (max-width:450px){
                                            font-size: 0.3rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modelsOutWrapDetached{
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            .detachedItems{
                width:32.25%;
                margin:1% 1% 0 0;
                position: relative;
                overflow: hidden;
                &:nth-of-type(3n){
                    margin-right:0px;
                }
                @media screen and (max-width:768px){
                    width:49.5%;
                    margin:1% 1% 0 0;
                    &:nth-of-type(3n){
                        margin-right:1%;
                    }
                    &:nth-of-type(2n){
                        margin-right:0px;
                    }
                    
                }
                
                .details{
                    position: absolute;
                    top:100%;
                    left:0;
                    width:100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .sBayLogo{
                        width:70%;
                        max-width:70px;
                        margin:1rem auto;
                        opacity:0;
                        @media screen and (max-width:450px){
                            max-width:45px;
                            margin:0rem auto 0.25rem auto;
                        }
                    }
                    .title1{
                        font-size: 1.5rem;
                        line-height: 120%;
                        opacity:0;
                        // transition:opacity .3s ease-in-out 0s;
                        @media screen and (max-width:1300px){
                            font-size: 1.5rem;
                        }
                        @media screen and (max-width:992px){
                            font-size: 1.25rem;
                        }
                        @media screen and (max-width:450px){
                            font-size: 1rem;
                        }
                    }
                    .extraInfo{
                        font-size: 1.5rem;
                        line-height: 120%;
                        opacity:0;
                        // transition:opacity .3s ease-in-out 0s;
                        @media screen and (max-width:1300px){
                            font-size: 1.25rem;
                        }
                        @media screen and (max-width:992px){
                            font-size: 1.25rem;
                        }
                        @media screen and (max-width:450px){
                            font-size: 0.75rem;
                        }
                    }
                    .midWrap{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        .sqft{
                            opacity:0;
                            // transition:opacity .3s ease-in-out 0s;
                            font-size: 0.85rem;
                            border-right: #fff 1px solid;
                            line-height: 100%;
                            padding:0 0.5rem;
                            margin:0.5rem auto;
                            &:last-of-type{
                                border-right:none;
                            }
                            @media screen and (max-width:1300px){
                                font-size: 0.85rem;
                            }
                            @media screen and (max-width:992px){
                                font-size: 0.85rem;
                            }
                            @media screen and (max-width:450px){
                                font-size: 0.75rem;
                                padding:0 0.5rem;
                                margin:0.15rem auto;
                            }
                        }
                    }
                    .aLink{
                        width:50%;
                        min-width:100px;
                        margin-top:0.5rem;
                        opacity:0;
                        // transition:opacity .3s ease-in-out 0s;
                        color:#fff;
                        @media screen and (max-width:450px){
                            width:80%;
                            margin-top:0.25rem;
                        }
                        &:hover{
                            text-decoration: none;
                            color:#fff;
                        }
                        .linkBtn{
                            text-align: center;
                            margin-top:0.5rem;
                            padding:3px 0px;
                            width:100%;
                            border: 1px  solid;
                            font-size:14px;
                            color:#fff;
                            @media screen and (max-width:1300px){
                                font-size:12px;
                            }
                            @media screen and (max-width:450px){
                                margin-top:0;
                                font-size: 10px;
                                padding:3px 5px;
                            }
                        }
                    }
                }
            }
            .defaultStyle{
                .bgColorLayerDetach{
                    width:100%;
                    height: 100%;
                    position: absolute;
                    top:0;
                    opacity: 0.8;
                }
                .details{
                    position: absolute;
                    top:0;
                    -webkit-animation: fade-in 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                    animation: fade-in 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                    background-color: rgba(0,0,0,0);
                    color:#fff;
                    .sBayLogo{
                        -webkit-animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation-name: fade-in, slide-in-bottom;
                    }
                    .title1{
                        // opacity:1;
                        -webkit-animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation-name: fade-in, slide-in-bottom;
                    }
                    .extraInfo{
                        -webkit-animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation-name: fade-in, slide-in-bottom;
                    }
                    .sqft{
                        -webkit-animation: fade-in 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: fade-in 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation-name: fade-in, slide-in-bottom;
                    }
                    .aLink{
                        -webkit-animation: fade-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: fade-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation-name: fade-in, slide-in-bottom;
                    }
                }
            }
        }
        // styles for mobile - townhomes
        .bigBgWrap_M{
            display: none;
            @media screen and (max-width:450px){
                display: flex;
                flex-direction: row;
                width:100%;
                margin-top:20px;
            }
            .left{
                width:22%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .arrowL{
                    width:30px;
                    height: 30px;
                }
            }
            .middle{
                width:55%;
                .bgWrap{
                    width:100%;
                    overflow: hidden;
                    // margin:20px auto 0 auto;
                    .rescaleImg{
                        height: 60vh;
                        .imgMover{
                            height:100%; 
                            width:auto;
                            transition:all 0.5s ease;
                        }
                    }
                }
                .textWrap{
                    width:100%;
                    margin:0 auto;
                    background-color:#008C99;
                    color:#fff;
                    padding:10px;
                    .title{
                        line-height: 110%;
                        font-size: 1.15rem;
                    }
                    .topPartDetailMobile{
                        margin:0.5rem auto;
                        .extra{
                            line-height: 110%;
                            // height: 20px;
                            font-size: 0.75rem;
                            border-right:solid 1px #333;
                            padding-right:0.5rem;
                        }
                        .sqft{
                            line-height: 110%;
                            // padding:0 0 5px 0;
                            font-size: 0.75rem;
                            padding-left:0.5rem;
                        }
                    }
                    .aLink{
                        width:80%;
                        margin-top:2rem;
                        // opacity:0;
                        // transition:opacity .3s ease-in-out 0s;
                        @media screen and (max-width:450px){
                            margin-top:2rem;
                        }
                        &:hover{
                            text-decoration: none;
                        }
                        .linkBtn{
                            color:#fff;
                            text-align: center;
                            padding:5px 0px;
                            width:100%;
                            border: 1px  solid;
                            font-size:12px;
                            @media screen and (max-width:1300px){
                                font-size:10px;
                            }
                            @media screen and (max-width:450px){
                                padding:3px 0px;
                                font-size: 0.75rem;
                            }
                        }
                    }
                }
            }
            .right{
                width:22%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .arrowR{
                    width:30px;
                    height: 30px;
                    transform: rotate(180deg);
                }
            }
        }
        .bigBgWrapDetached_M{
            display: none;
            @media screen and (max-width:450px){
                display: flex;
                flex-direction: row;
                width:100%;
                margin-top:20px;
            }
        }
        .relatedLink{
            display: flex;
            flex-flow:row wrap;
            width:90%;
            margin:1rem auto;
            @media screen and (max-width:500px) {
                flex-flow:row wrap; 
            }
            .oneBtn{
                // width:calc(33% - 2px);
                width:calc(50% - 2px);
                background-color:#008C99;
                color:#fff;
                margin: 0px 2px 2px 0px;
                @media screen and (max-width:768px){
                    width:100%;
                    margin: 0px 0px 2px 0px;
                }
                @media screen and (max-width:450px){
                    width:100%;
                    margin: 0px 0px 10px 0px;
                }
                .oneBtnLink{
                    color:#fff;
                    padding:5px;
                    display:block;
                    font-size:1rem;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.shrink{
    font-size: 1rem;
    line-height: 120%;
    font-weight: 700;
    @media screen and (max-width:450px){
        font-size: 0.3rem;
    }
}
</style>